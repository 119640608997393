<template>
  <div id="cardFacturacion" class="box p-5 zoom-in scroll-ajuste h-full" @click="handleShow">
    <template v-if="!verMas">
      <div class="grid  grid-rows-2">
        <div class="grid grid-cols-2 gap-4">
          <div class="flex flex-col">
            <span class="font-medium" style="font-size: 16px"> Vlr Contrato</span>
          </div>
          <div class="flex flex-col">
            <span>
              {{ $h.formatCurrency(VlrContratado) }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex gap-4 mb-3">
        <div class="flex w-full gap-x-1">
          <div class="flex flex-col" style="width: 24%">
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
                Total
            </span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
                Saldo
            </span>
          </div>
        </div>
      </div>
      <div class="flex gap-4 mb-1">
        <div class="flex w-full gap-x-1">
          <div class="flex flex-col" style="width: 24%">
            <span class="font-medium"> Vlr Fact: </span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
                {{ $h.formatCurrency(dataFacturacion.totalFacturadoAnio) }}
            </span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
              {{ $h.formatCurrency(VlrContratado - dataFacturacion.totalFacturadoAnio) }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex gap-4 mb-4">
        <div class="flex w-full gap-x-1">
          <div class="flex flex-col" style="width: 24%">
            <span class="font-medium"> Vlr Rad: </span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
              {{ $h.formatCurrency(dataFacturacion.totalRadicadoAnio) }}
            </span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
               {{ $h.formatCurrency(dataFacturacion.totalFacturadoAnio - dataFacturacion.totalRadicadoAnio) }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex gap-4 mb-1">
        <div class="flex w-full gap-x-1">
          <div class="flex flex-col" style="width: 24%">
            <span class="font-medium">Cartera:</span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
              {{ $h.formatCurrency(dataFacturacion.totalCartera) }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex gap-4 mb-2">
        <div class="flex w-full gap-x-1">
          <div class="flex flex-col" style="width: 24%">
            <span class="font-medium"> Pagos: </span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
              {{ $h.formatCurrency(dataFacturacion.totalFacturadoAnio - dataFacturacion.totalCartera) }}
            </span>
          </div>
        </div>
      </div>
      <div class="flex gap-4 mb-2">
        <div class="flex w-full gap-x-1">
          <div class="flex flex-col" style="width: 24%">
            <span class="font-medium"> Costo: </span>
          </div>
          <div class="flex flex-col" style="width:38%">
            <span>
              {{ $h.formatCurrency(dataFacturacion.totalCostoMx) }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex items-center justify-between w-full mb-2">
        <div class="font-medium truncate text-sm">Facturación Anual</div>
      </div>
      <div class="w-full overflow-x-auto letra">
        <div class="w-full flex">
          <div class="font-medium" style="width:26%;">Mes</div>
          <div class="font-medium" style="width:37%;">Vlr Facturado.</div>
          <div class="font-medium" style="width:37%;">Vlr Radicado</div>
        </div>
        <div
          v-for="(row, k) in detalleFacturacion"
          :key="k"
          class="w-full flex flex-col"
        >
          <div class="flex w-full">
            <div
              class="mb-1 text-primary-1 dark:text-white flex lg:hidden capitalize "
              style="width:26%;"
            >
              <!-- {{ row.CardName }} -->
              {{ listMes(row.mes-1, 1) }}
            </div>
            <div
              class="mb-1 text-primary-1 dark:text-white hidden lg:flex capitalize "
              style="width:26%;"
            >
              <!-- {{ row.CardName }} -->
              {{ listMes(row.mes-1, 2) }}
            </div>
            <div class="mb-1" style="width:37%;">
              {{ $h.formatCurrency(row.totalSinRa) }}
              <!-- {{ $h.formatNumber(row.cantSinRa) }} -->
            </div>
            <div class="mb-1" style="width:37%;">
                {{ $h.formatCurrency(row.totalRa) }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import storeCardFacturacion from './store/cardFacturacion.store'
import storeFiltro from './store/filtro.store'
import dayjs from 'dayjs'
import { computed, ref, watch } from 'vue'
export default {
  name: 'cardFacturacion',
  setup (props, { emit }) {
    /** data **/
    const verMas = ref(false)
    // const VlrContratado = ref(props.VlrContratado)
    /** computed **/
    const dataFacturacion = computed(
      () => storeCardFacturacion.getters.dataFacturacion
    )
    const anio = computed(() => storeFiltro.getters.anio)
    const nameMes = computed(() =>
      dayjs(dayjs().month(storeFiltro.getters.mes)).format('MMMM')
    )
    const mes = computed(() => storeFiltro.getters.mes)
    const detalleFacturacion = computed(
      () => storeCardFacturacion.getters.detalleFacturacion
    )
    const VlrContratado = computed(
      () => storeCardFacturacion.getters.vlrContratado
    )
    const cardCode = computed(
      () => storeCardFacturacion.getters.cardCode
    )
    const contrato = computed(
      () => storeCardFacturacion.getters.contrato
    )
    /** watch **/
    watch(verMas, (a, b) => {
      if (b) {
        getData()
      } else {
        getDetalle()
      }
    })
    watch(anio, () => {
      getDetalle()
    })
    watch(contrato, () => {
      getDetalle()
    })
    watch(cardCode, () => {
      verMas.value = false
    })
    /** methods **/
    const listMes = (mes, num) => {
        if (num === 1) {
            return dayjs(dayjs().month(mes)).format('MMM')
        } else {
            return dayjs(dayjs().month(mes)).format('MMMM')
        }
    }
    const handleShow = () => {
      verMas.value = !verMas.value
    }
    const getDetalle = () => {
      storeCardFacturacion.dispatch('getDetalleFacturacion', {
        anio: anio.value,
        cardCode: cardCode.value,
        contrato: contrato.value
      })
    }
    const getData = () => {
      emit('getData')
    }
    return {
      dataFacturacion,
      anio,
      mes,
      handleShow,
      listMes,
      verMas,
      nameMes,
      detalleFacturacion,
      VlrContratado
    }
  }
}
</script>
<style scoped>
  @media (max-width: 800px) {
    #cardFacturacion {
    font-size: 13px;
  }
}
</style>
